<template>
    <div>
        <ClipboardLinkButton :link="url + (onlyFirst ? '?only_first_days' : '')" :class="small ? 'btn-sm' : ''" />
        <a :href="url + (onlyFirst ? '?only_first_days' : '')" :class="['btn', 'btn-outline-primary', 'm-1', small ? 'btn-sm' : '']">
            <Icon v="calendar-plus" />
            <T>crud.download</T>
            .ics
        </a>

        <div class="form-check form-switch small">
            <label>
                <input v-model="onlyFirst" class="form-check-input" type="checkbox">
                <T>calendar.onlyFirstDays</T>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        url: { required: true },
        small: { type: Boolean },
    },
    data() {
        return {
            onlyFirst: true,
        };
    },
};
</script>
