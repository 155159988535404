<template>
    <button ref="clipboard" :class="['btn', clipboardFeedback ? 'btn-success' : 'btn-outline-primary', 'm-1']" :data-clipboard-text="link">
        <Icon :v="clipboardFeedback ? 'clipboard-check' : 'clipboard'" />
        <T>crud.copy</T>
    </button>
</template>

<script>
import ClipboardJS from 'clipboard';

export default {
    props: {
        link: { required: true },
    },
    data() {
        return {
            clipboardFeedback: false,
        };
    },
    mounted() {
        if (!this.$refs.clipboard) {
            return;
        }
        const clipboard = new ClipboardJS(this.$refs.clipboard);
        clipboard.on('success', () => {
            this.clipboardFeedback = true;
            setTimeout(() => this.clipboardFeedback = false, 3000);
        });
    },
};
</script>
